<template>

<app-panel v-on:close="$emit('close')">
		
	<app-panel-title text="Edit badge" />

	<app-panel-text>

		You can assign a badge to a specific user so they can login and manage their badge like any other attendee. Once assigned it cannot be undone without contacting support.

	</app-panel-text>

	<app-panel-content>

		<app-input-account label="E-mail" :stacked="true" :original="''" endpoint="convention/registration/managed/exists" :reverse="true" type="badge" :text="emailMessages" v-model="model.email" :validation="$v.model.email" v-on:exists="onExistsChange" />
		<app-input-text label="Firstname" v-model="model.firstname" :validation="$v.model.firstname" :stacked="true" placeholder="Enter firstname" />
		<app-input-text label="Lastname" v-model="model.lastname" :validation="$v.model.lastname" :stacked="true" placeholder="Enter lastname" />

	</app-panel-content>

	<app-panel-content>

		<app-button text="Confirm" :disabled="$v.$invalid" :status="status" :loading="is.loading" v-on:click="onConfirmClick" />

	</app-panel-content>

</app-panel>

</template>

<script>

import { required, email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate';

export default {

	mixins: [validationMixin],

	props: ['item'],

	data: function() {

		return {
			status: false,
			is: {
				loading: false,
				saved: false
			},
			model: {
				email: '',
				firstname: '',
				lastname: ''
			},
			emailMessages: {
				info: 'Please note the e-mail address cannot already be associated with an existing badge.',
				exists: 'This e-mail address is already associated with another badge.',
				available: 'This e-mail is available to use. A user account will be created and login credentials will automatically be sent to the assigned badge holder.'
			},
			exists: false
		}

	},

	validations: {
		model: {
			firstname: {
				required
			},
			lastname: {
				required
			},
			email: {
				required,
				email,
				notExist: function() {

					return !this.exists

				}
			}
		}
	},

	created: function() {

		this.$pubsub.$emit('offset', true)

		this.model.firstname = this.item.firstname
		this.model.lastname = this.item.lastname

		this.$nextTick(function() {
			
			this.$v.$reset()

		}.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$emit('offset', false)

	},

	methods: {

		onExistsChange: function(e) {

			this.exists = e.exists

		},

		onConfirmClick: function() {

			this.is.loading = true
			this.status = false

			this.$api.put(['convention/registration/managed/assign', this.item.id], this.model).then(function() {

				this.is.loading = false
				this.is.saved = true

				this.status = this.$constants.status.success
				
				this.$emit('saved')
				this.$emit('close')

			}.bind(this), function() {

				this.is.loading = false

				this.status = this.$constants.status.failed

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.connect {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px 0px;
}

.connect-avatar {
	width: 128px;
	height: 128px;
	border-radius: 50%;
	background-size: cover;
	background-position: 50% 50%;
	margin-bottom: 10px;
}

.connect-username {
	font-size: 20px;
	letter-spacing: 1px;
}

</style>
